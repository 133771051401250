import { Box, Input, Text, TextArea } from "@thrivecoin/ui-components";
import {
  DateTime,
  FloatInput,
  InputCheckbox,
  InputGroup,
  InputHidden,
  InputSelect,
  IntegerInput,
  RichText,
  TextList,
  wrappedMultiSelect,
} from "../../common";
import ReviewOptions from "../../ReviewOptions";

const TYPE_CLASS_MAP = {
  text: Input,
  number: IntegerInput,
  decimal: FloatInput,
  textarea: TextArea,
  hidden: InputHidden,
  checkbox: InputCheckbox,
  select: InputSelect,
  json: TextList,
  richtext: RichText,
  datetime: DateTime,
};

const Separator = (props) => <Box $height="1px" $spacing="my-6" $bgColor="01Primary200" {...props} />;

function insertSeparator(array, Klass) {
  const newArray = [];

  for (let i = 0; i < array.length; i++) {
    newArray.push(array[i]);
    if (i < array.length - 1) {
      newArray.push(<Klass key={`separator-${i}`} />);
    }
  }

  return newArray;
}

const TemplateForm = ({ templateFields, setState, formData, badges }) => {
  if (!templateFields.length) {
    return null;
  }

  let fields = templateFields.map(({ placeholder, options, value, name, type, required, key, disabled }) => {
    let _options = options;
    let inputClass = TYPE_CLASS_MAP[type];

    if (key === "eligible_badge_ids") {
      _options = badges;
      inputClass = wrappedMultiSelect(_options);
    }

    if (key === "review_options") {
      return <ReviewOptions key={key} name="review_options_attributes" value={value} setState={setState} />;
    }

    const labelSuffix = required ? "Required" : "Optional";
    const label = `${name} (${labelSuffix})`;

    return (
      <InputGroup
        key={key}
        field={key}
        label={type === "hidden" ? "" : label}
        fieldName={name}
        value={formData[key] || value}
        defaultValue={formData[key] || value}
        defaultChecked={formData[key] === undefined ? value : formData[key]}
        InputClass={inputClass}
        options={_options}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        setState={setState}
        idKey="value"
        valuesOnly
      />
    );
  });

  fields = insertSeparator(fields, Separator);

  return (
    <Box $spacing="mt-4" $color="01Primary700">
      <Separator />
      <Text $typography="heading/small" $spacing="mb-6">
        Submission and Validation
      </Text>
      {fields}
    </Box>
  );
};

export default TemplateForm;
