import createLoadingThunks from "../createLoadingThunks";
import activity from "./activity";
import authentication from "./authentication";
import badges from "./badges";
import challenges from "./challenges";
import commitChallenge from "./commitChallenge";
import community from "./community";
import contributionApps from "./contributionApps";
import contributions from "./contributions";
import contributors from "./contributors";
import entities from "./entities";
import featureSwitches from "./featureSwitches";
import listing from "./listing";
import listings from "./listings";
import listingSeasons from "./listingSeasons";
import media from "./media";
import members from "./members";
import modals from "./modals";
import notification from "./notification";
import notifications from "./notifications";
import pendingContributions from "./pendingContributions";
import preferences from "./preferences";
import raffle from "./raffle";
import rewards from "./rewards";
import season from "./season";
import services from "./services";
import tags from "./tags";
import theme from "./theme";
import toasts from "./toasts";
import user from "./user";
import userAudits from "./userAudits";
import userBadge from "./userBadge";
import wallets from "./wallets";

export default createLoadingThunks({
  activity,
  authentication,
  badges,
  challenges,
  commitChallenge,
  community,
  contributionApps,
  contributions,
  contributors,
  entities,
  listing,
  listings,
  listingSeasons,
  media,
  members,
  modals,
  notification,
  notifications,
  pendingContributions,
  preferences,
  raffle,
  rewards,
  season,
  services,
  tags,
  theme,
  toasts,
  user,
  wallets,
  userAudits,
  featureSwitches,
  badges,
  userBadge,
});
