import { useContext } from "react";
import styled, { css, keyframes, ThemeContext } from "styled-components";

// TODO: Convert this into a grid
const Loader = ({ className }) => {
  return (
    <Container className={className}>
      <LoadingLogo />
    </Container>
  );
};

export const LoadingLogo = () => {
  const { themeName } = useContext(ThemeContext);
  const isDark = themeName === "dark";

  return (
    <svg width="149" height="52" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 32">
      <LeafAnimation
        d="M47.3169 5.37119L49.0639 7.11818L50.8241 5.35797C51.0626 5.11941 51.2523 4.83667 51.3824 4.52589C51.5124 4.21512 51.5802 3.88238 51.5819 3.5467C51.5836 3.21102 51.5191 2.87895 51.3922 2.56947C51.2653 2.25999 51.0785 1.97914 50.8423 1.74297L49.0993 -3.36133e-05L47.335 1.76421C46.8543 2.24493 46.5823 2.89502 46.5789 3.57146C46.5755 4.2479 46.841 4.89528 47.3169 5.37119Z"
        fill={isDark ? "#fff" : "#8DCF9F"}
      />
      <LeafAnimation
        d="M49.9453 9.46541L49.9453 12.1252L52.5987 12.1252C52.9583 12.1252 53.3144 12.0544 53.6467 11.9168C53.9789 11.7792 54.2808 11.5775 54.5351 11.3233C54.7894 11.069 54.9911 10.7672 55.1287 10.435C55.2663 10.1028 55.3372 9.74673 55.3372 9.38716L55.3372 6.7334L52.6777 6.7334C51.953 6.7334 51.258 7.02123 50.7456 7.53359C50.2332 8.04594 49.9453 8.74083 49.9453 9.46541Z"
        fill={isDark ? "#fff" : "#8DCF9F"}
      />
      <LeafAnimation
        d="M42.7559 9.39287L42.7559 6.73304L45.4477 6.73304C45.8126 6.73304 46.1738 6.80386 46.5109 6.94146C46.8479 7.07906 47.1542 7.28075 47.4122 7.53501C47.6701 7.78926 47.8748 8.0911 48.0144 8.4233C48.154 8.7555 48.2258 9.11155 48.2258 9.47112L48.2258 12.1249L45.5278 12.1249C44.7926 12.1249 44.0876 11.837 43.5677 11.3247C43.0479 10.8123 42.7559 10.1174 42.7559 9.39287Z"
        fill={isDark ? "#fff" : "#8DCF9F"}
      />
      <LogoAnimation
        d="M11.9218 14.3135H7.88946V7.46912H4.03237V14.3135H0V17.7357H4.03237V30.7382H7.88946V17.7357H11.9218V14.3135Z"
        fill={isDark ? "#81C394" : "#35476A"}
      />
      <LogoAnimation
        d="M43.3826 14.6588C42.6758 14.3591 41.9118 14.2097 41.1413 14.2205C39.2551 14.2205 37.8466 14.8556 36.9158 16.1257C36.8859 16.1657 36.858 16.2075 36.8301 16.2485V14.5575H33.0361V30.7382H36.8301V21.7381C36.8301 20.3446 37.1749 19.3219 37.8646 18.6699C38.5542 18.0179 39.4279 17.6933 40.4856 17.6959C40.9197 17.6879 41.3518 17.7562 41.7612 17.8975C42.1427 18.0435 42.484 18.2746 42.7578 18.5725L45.2452 16.078C44.6705 15.4247 44.0497 14.9517 43.3826 14.6588Z"
        fill={isDark ? "#81C394" : "#35476A"}
      />
      <LogoAnimation d="M46.9688 30.7381V14.9381H51.2778V30.7381H46.9688Z" fill={isDark ? "#81C394" : "#35476A"} />
      <LogoAnimation
        d="M66.1444 14.9381L61.6908 25.0425L57.2645 14.9381H53.1455L60.5396 30.7381H62.7005L70.0946 14.9381H66.1444Z"
        fill={isDark ? "#81C394" : "#35476A"}
      />
      <LogoAnimation
        d="M86.1615 18.2522C85.5102 17.0319 84.5421 16.0111 83.3598 15.2979C82.1697 14.5796 80.8022 14.2204 79.2572 14.2204C77.7517 14.2052 76.2692 14.5914 74.9611 15.3394C73.653 16.0875 72.5661 17.1706 71.8117 18.4781C71.0505 19.7869 70.6696 21.2638 70.6689 22.9086C70.6683 24.5535 71.0552 26.0363 71.8297 27.3572C72.5824 28.6599 73.6709 29.7352 74.9806 30.4702C76.3074 31.2233 77.8023 31.5999 79.4654 31.5999C80.7252 31.6064 81.9735 31.3581 83.1356 30.8698C84.273 30.3959 85.282 29.6576 86.0795 28.7158L83.655 26.2475C83.1482 26.8262 82.5141 27.2783 81.8028 27.5681C81.0496 27.8747 80.2432 28.0283 79.4304 28.0199C78.4377 28.0199 77.5605 27.8057 76.7987 27.3773C76.0438 26.9569 75.4294 26.3222 75.0326 25.5527C74.8315 25.168 74.6796 24.7594 74.5804 24.3366H86.979C87.0491 23.9891 87.1011 23.6705 87.1351 23.3806C87.1695 23.0981 87.1869 22.8137 87.1871 22.529C87.1878 20.9284 86.8459 19.5028 86.1615 18.2522ZM74.9936 20.111C75.368 19.3777 75.945 18.7683 76.6556 18.3556C77.3714 17.9392 78.2143 17.731 79.1842 17.731C80.1068 17.731 80.8859 17.9161 81.5216 18.2864C82.1595 18.6609 82.6718 19.2174 82.9936 19.885C83.1961 20.3042 83.3435 20.7481 83.4318 21.2055H74.5884C74.6794 20.8257 74.8154 20.4582 74.9936 20.111Z"
        fill={isDark ? "#81C394" : "#35476A"}
      />
      <LogoAnimation
        d="M97.7735 31.5998C96.1616 31.5998 94.704 31.2176 93.4008 30.4533C92.1183 29.7115 91.0546 28.6314 90.3212 27.326C89.5719 26.0054 89.1976 24.5329 89.1982 22.9085C89.1982 21.264 89.5725 19.7875 90.3212 18.4791C91.0582 17.1803 92.1213 16.1059 93.4008 15.3668C94.7047 14.6024 96.1623 14.2202 97.7735 14.2202C98.9971 14.2104 100.209 14.4649 101.329 14.9672C102.412 15.4524 103.372 16.1834 104.136 17.1046L101.687 19.6406C101.224 19.072 100.635 18.6245 99.9683 18.3355C99.2728 18.0412 98.5263 17.8936 97.7735 17.9018C96.9094 17.884 96.0567 18.106 95.3065 18.5443C94.6011 18.9602 94.0224 19.5677 93.6349 20.2992C93.2376 21.0401 93.039 21.9102 93.039 22.9095C93.0219 23.8096 93.2268 24.6997 93.6349 25.4977C94.0185 26.2366 94.5977 26.8506 95.3065 27.2697C96.0203 27.6994 96.8427 27.9139 97.7735 27.9133C98.5264 27.9216 99.2731 27.7737 99.9683 27.4785C100.635 27.1903 101.225 26.7427 101.687 26.1734L104.136 28.7104C103.372 29.6316 102.412 30.3624 101.329 30.8468C100.209 31.3512 98.9975 31.6078 97.7735 31.5998Z"
        fill={isDark ? "#fff" : "#8DCF9F"}
      />
      <LogoAnimation
        d="M114.031 31.5993C112.503 31.6187 110.999 31.2165 109.68 30.4358C108.395 29.6774 107.327 28.594 106.579 27.2915C105.808 25.9521 105.411 24.4251 105.43 22.8743C105.43 21.254 105.813 19.795 106.579 18.4973C107.334 17.209 108.402 16.1377 109.68 15.3851C110.999 14.6041 112.503 14.2016 114.031 14.2206C115.653 14.2206 117.115 14.6028 118.417 15.3671C119.7 16.1131 120.769 17.1859 121.518 18.4792C122.284 19.7876 122.666 21.2523 122.665 22.8733C122.685 24.424 122.289 25.951 121.518 27.2905C120.77 28.593 119.702 29.6764 118.417 30.4348C117.115 31.2111 115.653 31.5993 114.031 31.5993ZM114.031 27.917C114.901 27.9347 115.76 27.7123 116.515 27.2735C117.232 26.8506 117.815 26.2303 118.198 25.4845C118.606 24.6776 118.81 23.7808 118.792 22.8743C118.792 21.9025 118.587 21.0459 118.176 20.3043C117.779 19.5783 117.198 18.9726 116.493 18.5495C115.784 18.1211 114.962 17.907 114.026 17.907C113.167 17.8922 112.321 18.1143 111.577 18.5495C110.866 18.9654 110.284 19.5728 109.894 20.3043C109.494 21.0452 109.294 21.9019 109.294 22.8743C109.277 23.7814 109.483 24.6783 109.894 25.4845C110.276 26.2305 110.86 26.8509 111.577 27.2735C112.322 27.7093 113.171 27.9307 114.031 27.914V27.917Z"
        fill={isDark ? "#fff" : "#8DCF9F"}
      />
      <LogoAnimation
        d="M127.692 11.6244C127.406 11.6326 127.12 11.5799 126.855 11.4697C126.59 11.3595 126.35 11.1943 126.151 10.9845C125.757 10.555 125.538 9.98987 125.538 9.40298C125.538 8.81608 125.757 8.25098 126.151 7.82143C126.35 7.61202 126.59 7.44704 126.855 7.33704C127.121 7.22704 127.406 7.17444 127.692 7.18258C128.331 7.18258 128.85 7.39553 129.25 7.82143C129.634 8.25561 129.847 8.81897 129.847 9.40298C129.847 9.98699 129.634 10.5503 129.25 10.9845C128.852 11.4117 128.333 11.625 127.692 11.6244ZM125.872 30.7382V14.5852H129.518V30.7382H125.872Z"
        fill={isDark ? "#fff" : "#8DCF9F"}
      />
      <LogoAnimation
        d="M147.855 17.541C147.323 16.5715 146.558 15.7492 145.63 15.1478C144.687 14.5303 143.609 14.2212 142.396 14.2206C141.27 14.2052 140.161 14.4903 139.182 15.0465C138.574 15.3941 138.032 15.8465 137.581 16.3829V14.5576H133.869V30.7382H137.576V21.4011C137.564 20.7305 137.727 20.0682 138.048 19.4794C138.354 18.9265 138.809 18.4709 139.362 18.1644C139.951 17.843 140.613 17.6806 141.283 17.693C142.362 17.693 143.243 18.0414 143.928 18.7381C144.613 19.4349 144.956 20.3225 144.957 21.4011V30.7382H148.664V20.5936C148.66 19.5235 148.382 18.4722 147.855 17.541Z"
        fill={isDark ? "#fff" : "#8DCF9F"}
      />
      <LogoAnimation
        d="M28.339 17.0696C27.8122 16.1188 27.0353 15.3322 26.0936 14.7958C25.1413 14.2481 24.0527 13.9742 22.8277 13.9742C21.6898 13.9577 20.5681 14.2471 19.5786 14.8126C18.9649 15.1659 18.4179 15.625 17.9624 16.169V7.46912H14.2197V30.7343H17.9624V21.2574C17.9499 20.5766 18.1143 19.9043 18.4392 19.3068C18.7478 18.7455 19.2075 18.283 19.7654 17.9722C20.3597 17.6461 21.0282 17.4813 21.705 17.4937C22.7937 17.4937 23.684 17.8473 24.3761 18.5545C25.0682 19.2617 25.4146 20.164 25.4153 21.2613V30.7382H29.1579V20.4398C29.1566 19.1651 28.8836 18.0417 28.339 17.0696Z"
        fill={isDark ? "#81C394" : "#35476A"}
      />
    </svg>
  );
};


const AnimationLeaf = keyframes`
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
`;

const LoadingAnimationLeaf = css`
  animation: ${AnimationLeaf} 0.3s linear infinite alternate;
`;

const LogoOpacityThrive = keyframes`
    0% { opacity: 0; }
    20% { opacity: 0.2; }
    40% { opacity: 0.4; }
    60% { opacity: 0.6; }
    80% { opacity: 0.8; }
    100% { opacity: 1; }
`;

const LogoAnimationOpacityThrive = css`
  animation: ${LogoOpacityThrive} 0.6s linear infinite alternate;
`;

const Container = styled.div`
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors["01Primary0"]};
  display: flex;
  z-index: 9;
`;

const LeafAnimation = styled.path`
  ${LoadingAnimationLeaf}
`;

const LogoAnimation = styled.path`
  ${LogoAnimationOpacityThrive}
`;

export default Loader;
