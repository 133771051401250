import { Box, Text, TextArea } from "@thrivecoin/ui-components";

const INITIAL_STATE = [{ prompt: "" }, { prompt: "" }];
const configs = [
  {
    label: "Answer A",
    placeholder:
      "Eg. I believe that through collective efforts, we can contribute to innovations and promote decentralisation, improving technology and accelerating the adoption of digital currencies.",
  },
  {
    label: "Answer B",
    placeholder:
      "E.g. While I feel responsible for the crypto community, I have reservations. The lack of regulation may lead to irresponsible behavior, scams, and a potential decline in trust in this digital ecosystem.",
  },
];

const ReviewOptions = ({ name, value, setState }) => {
  const list = value || [...INITIAL_STATE];

  const onInputChange = (e) => {
    const { value, dataset } = e.target;
    const { index } = dataset;
    const newList = [...list];

    newList[index].prompt = value;
    setState((state) => ({ ...state, [name]: newList }));
  };

  return (
    <Box $flex $direction="column" $gap="32px">
      {list.map((item, index) => (
        <Box key={item.id || index}>
          <Text $bold $spacing="mb-3">
            {configs[index].label} (Required)
          </Text>
          <TextArea
            value={list[index].prompt}
            onChange={onInputChange}
            data-index={index}
            required
            placeholder={configs[index].placeholder}
          />
        </Box>
      ))}
    </Box>
  );
};

export default ReviewOptions;
