import { Box, Text } from "@thrivecoin/ui-components";
import { uniqueArray } from "@utils/array";
import { useStoreState } from "easy-peasy";
import { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import FileInput from "../../../common/FileInput";
import { InputGroup, Select, wrappedMultiSelect, wrappedSelect } from "../../common";
import { ListingEditorContext } from "../../ListingEditorContext";
import { ConditionsForm } from "./Forms";
import TemplateForm from "./TemplateForm";

const TypeSelectionStep = () => {
  const { isEditing, listing, setState, templates, templateObject } = useContext(ListingEditorContext);
  const { activeNetworks } = useStoreState((state) => state.listings);
  const { communityBadges } = useStoreState((state) => state.badges);
  const { verification_method, template, network, conditions_allowed, network_image_url, network_ids } = listing;
  const verification_locked = true;
  const showConditions = conditions_allowed;
  const showTemplateSelection = !isEditing;
  const templateFields = templateObject.variables;
  const _communityBadges = communityBadges.map(({ id, logo_url, name }) => ({ value: id, label: name, url: logo_url }));

  const networks = useMemo(() => {
    return uniqueArray(templates.map(({ network }) => network));
  }, [templates]);

  const filteredTemplates = templates.filter((template) => template.network == network);
  const networkOptions = networks.map((name) => ({ label: name, value: name }));
  const activeNetworksOptions = activeNetworks.map((net) => ({ ...net, label: net.name, value: net.id }));
  const additionalNetworkOptions = activeNetworksOptions.filter((item) => item.label !== network);
  const templateOptions = filteredTemplates.map(({ name }) => ({ label: name, value: name }));
  const selectedNetworks = network_ids
    ? additionalNetworkOptions.filter((obj) => network_ids.some((selected) => selected === obj.id))
    : [];

  useEffect(() => {
    if (network) {
      setState((state) => {
        if (state.network !== undefined && state.network !== network) {
          return { ...state, template: null, contribution_app: null };
        }
        return state;
      });
    }
  }, [network]);

  return (
    <Container>
      <InputGroup
        label="Contribution App (Required)"
        field="network"
        InputClass={wrappedSelect(networkOptions)}
        defaultValue={network}
        required
        disabled={isEditing}
        $spacing="mb-3"
      />

      <Box $flex $spacing="mb-6" $alignItems="center">
        <FileInput
          $bold={false}
          label="Network Image 40x40 (Optional)"
          name="network_image_url"
          media={listing}
          setMedia={setState}
          accept="image/*"
        />
        {network_image_url && <PreviewImage src={network_image_url} />}
      </Box>

      {showTemplateSelection && (
        <InputGroup
          label="Select a template"
          field="template"
          InputClass={wrappedSelect(templateOptions)}
          defaultValue={template}
          required
          disabled={isEditing}
        />
      )}
      <Box>
        <Box disabled>
          <Text $bold $typography="body/medium" $spacing="mb-3" $color="01Primary700">
            Verification Method (Required)
          </Text>
          <Select
            key={verification_method}
            name="verification_method"
            defaultValue={verification_method}
            disabled={verification_locked || isEditing}
          >
            <option value="automatic">Automatic</option>
            <option value="manual">Manual</option>
          </Select>
        </Box>
      </Box>
      <Box $height="1px" $spacing="my-6" $bgColor="01Primary200" />
      <InputGroup
        field="network_ids"
        defaultValue={selectedNetworks}
        InputClass={wrappedMultiSelect(additionalNetworkOptions)}
        setState={setState}
        idKey="id"
        label="Additional Required Networks (Optional)"
        description={`If this contribution opportunity requires connections for verifying it was successfully completed
        <b>in addition </b> to the network selected above, please add the additional required network(s) below.`}
      />
      {showConditions && (
        <>
          <Box $height="1px" $spacing="my-6" $bgColor="01Primary200" />
          <ConditionsForm {...listing} setState={setState} />
        </>
      )}
      <TemplateForm formData={listing} templateFields={templateFields} setState={setState} badges={_communityBadges} />
    </Container>
  );
};

const PreviewImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 16px;
`;

const Container = styled.div`
  .verification {
    display: flex;
    gap: 1rem;
    align-items: center;
    max-width: 387px;

    select {
      width: unset;
    }
  }

  *[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  label[name="file-label"] {
    background: #f5f5f5;
    border: 1px solid #e4e4ed;
    border-radius: 4px;
    color: black;
    padding: 6px;
  }
`;

export default TypeSelectionStep;
