import { MainContainer } from "@common/MainContainer";
import { Box, Button, getTypography, Loader, SearchInput, Text } from "@thrivecoin/ui-components";
import { downloadBlobResponse } from "@utils/downloadBlob";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import LoadMore from "../common/LoadMore";
import useSort from "../hooks/useSort";
import { HeaderBox } from "../ManageCommunities/ManageTabsContainer";
import BreadCrumb from "./BreadCrumb";
import { IconContextMenu } from "./common";
import Table from "./Table";
import useSelectionColumn from "./useSelectionColumn";

const CommunityBadgeHolders = () => {
  const { id } = useParams();
  const { getBadgdeRequest, downloadUsersCSVRequest } = useStoreActions((actions) => actions.badges);
  const { byID: badgeByID } = useStoreState((state) => state.badges);
  const { getUsersByBadgeRequest, removeUsersRequest } = useStoreActions((actions) => actions.userBadge);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { byBadgeID, pagination } = useStoreState((state) => state.userBadge);
  const user_badges = byBadgeID(id);
  const { selectedIDs, onSelectChange, selectAll, clearSelection } = useSelectionColumn(".community-listings");
  const selectedCount = selectedIDs.length;
  const allSelected = selectedCount == user_badges.length;
  const badge = badgeByID(id);
  const { community_name, community_id, name } = badge;
  const text =
    selectedCount > 0
      ? `${selectedCount} Selected Ways to Contribute`
      : `There are ${pagination.total} contributors under ${name}`;

  const fetchItems = (props) => getUsersByBadgeRequest({ ...props, id }).then(clearSelection);
  const { onChange, loading, onLoadMore, ...sortProps } = useSort(fetchItems, "status", "id", id);

  const bulkUnassign = () => {
    const action = () => removeUsersRequest({ id: id, user_badge_ids: selectedIDs });
    showModal({
      modalName: "ConfirmDeletionModal",
      action,
      title: `Are you sure you want to unassign the selected ${selectedIDs.length} contributors from Badge ${badge?.name}`,
      warning: "",
      confirmText: "Unassign all of them",
      confirmIcon: "trash-bold",
    });
  };

  const unAssign = (event) => {
    const { id: userID } = event.currentTarget.dataset;
    const action = () => removeUsersRequest({ id, user_badge_ids: [userID] });
    const userBadge = user_badges.find((userBadge) => userBadge.id == userID);
    const identifier = userBadge.username || userBadge.identifier;
    showModal({
      modalName: "ConfirmDeletionModal",
      action,
      title: "Unassign Badge",
      warning: `Are you sure you want to unassign the badge from "${identifier}"?`,
    });
  };

  const assignBadge = () => {
    const image_urls = user_badges.map(({ image_url }) => image_url);
    showModal({ modalName: "AssignBadgeHolderModal", image_urls, id: badge.id, callback: () => fetchItems({}) });
  };
  const downloadCSV = () => {
    downloadUsersCSVRequest(id).then(downloadBlobResponse("users.csv"));
  };

  useEffect(() => {
    getBadgdeRequest(id);
  }, [id]);

  if (loading && user_badges.length == 0) {
    return (
      <Box $flex $justifyContent="center" $spacing="my-a">
        <Loader $size="64px" />
      </Box>
    );
  }

  return (
    <Box $spacing="pb-8">
      <HeaderBox $spacing="py-6+2">
        <MainContainer>
          <Box $flex $direction="column" $gap="14px">
            <BreadCrumb community_name={community_name} community_id={community_id} />
            <Box $flex $spacing="mx-a" $gap="16px" $alignItems="center">
              <Image src={badge.logo_url} />
              <Text $align="center" $typography="Heading/Large" $color="01Primary700">
                Manage Badge Contributors
              </Text>
            </Box>
          </Box>
        </MainContainer>
      </HeaderBox>
      <MainContainer>
        <Box $flex $direction="column" $spacing="py-6" $gap="32px">
          <Box onChange={onSelectChange}>
            <Box $flex $gap="16px" $alignItems="center" $minHeight="35px" $spacing="mb-4">
              <Text $bold $typography="Heading/Small">
                {text}
              </Text>
              {selectedIDs.length > 0 && (
                <IconContextMenu rightContainer={undefined} leftContainer={"56px"} topContainer={"-8px"}>
                  <StiledList>
                    <li onClick={clearSelection}>Clear Selection</li>
                    <li onClick={bulkUnassign}>
                      <b>
                        Unassign {selectedIDs.length} Selected Contributors from {name}
                      </b>
                    </li>
                  </StiledList>
                </IconContextMenu>
              )}

              <Box $flex $gap="16px" $spacing="ml-a">
                <Button $borderRadius="40px" $size="sm" rightIcon="plus" onClick={assignBadge}>
                  Assign Badge Contributors
                </Button>
                <Button
                  $borderRadius="40px"
                  $size="sm"
                  $borderColor="01Primary300"
                  kind="outline"
                  rightIcon="export-cloud-filled"
                  onClick={downloadCSV}
                >
                  Export as CSV
                </Button>
                <SearchInput className="search-input" onChange={onChange} />
              </Box>
            </Box>

            <Table
              allSelected={allSelected}
              selectAll={selectAll}
              items={user_badges}
              selectedIDs={selectedIDs}
              className="community-listings"
              rowPRops={{ unAssign }}
              sortProps={sortProps}
            />
            <LoadMore pagination={pagination} loadMore={onLoadMore} />
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
};

const StiledList = styled.ul`
  && {
    li {
      font-weight: normal;
      ${getTypography("body/medium")}

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
      }
    }
  }
`;

const Image = styled.img`
  width: 60x;
  height: 60px;
`;

export default CommunityBadgeHolders;
