import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import moment from "moment";
import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const DateInput = forwardRef(({ value, onClick, invalid }, ref) => (
  <StyledInput $bgColor="01Primary50" onClick={onClick} ref={ref} invalid={invalid}>
    <Text $bold>{moment(value).format("MMMM Do, YYYY")}</Text>
    <IconSvg icon="calendar-2" $color="01Primary700" $width="20px" />
  </StyledInput>
));

const TimeInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <StyledInput timeInput $bgColor="01Primary50" onClick={onClick} ref={ref}>
      <Text $bold>{moment(value).format("h:mm A")}</Text>
      <IconSvg icon="chevron-down" $color="01Primary700" $width="20px" />
    </StyledInput>
  );
});

DateInput.displayName = "DateInput";
TimeInput.displayName = "TimeInput";

const DateTimePicker = ({ value = new Date(), setValue, timeSelector, id, invalid, validationText }) => {
  const val = new Date(value);
  const convertedValue = new Date(val.getTime() + val.getTimezoneOffset() * 60000);

  const onChange = (val) => {
    const date = new Date(moment(val).utc(true));
    setValue(date, id);
  };

  if (timeSelector) {
    return (
      <StyledBox>
        <ReactDatePicker
          selected={convertedValue}
          onChange={onChange}
          dateFormat="MM/dd/yyyy HH:mm"
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          customInput={<TimeInput />}
        />
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      <ReactDatePicker
        calendarStartDay={1}
        selected={convertedValue}
        customInput={<DateInput invalid={invalid} />}
        onChange={onChange}
      />
      {!!validationText && (
        <Box $flex $alignItems="center" $gap="8px" $color="#f16d67">
          <IconSvg $width="16px" icon="alert-triangle" />
          <ValidationText>{validationText}</ValidationText>
        </Box>
      )}
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  .react-datepicker {
    font-family: "Proxima Nova";
    box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.15);
    color: ${({ theme }) => theme.colors["01Primary700"]};
    border: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
    border-radius: 12px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors["01Primary0"]};

    &-popper {
      z-index: 99;
    }

    &__triangle {
      display: none;
    }

    &__header {
      text-align: center;
      background-color: ${({ theme }) => theme.colors["01Primary0"]};
      border-bottom: 0px;
      position: relative;
      padding: 0;
    }

    &__current-month {
      color: unset;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 4px 8px;
      margin: 19px 0;
    }

    &__navigation {
      width: 24px;
      height: 34px;
      top: 19px;

      &-icon::before {
        border-width: 1px 1px 0 0;
        border-color: ${({ theme }) => theme.colors["01Primary700"]};
      }

      &--next {
        right: 18px;
      }

      &--previous {
        left: 18px;
      }
    }

    &__month {
      margin: 0 5.5px 1rem;
    }

    &__week {
      display: flex;
    }

    &__day {
      width: 48px;
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors["01Primary700"]};

      &:hover {
        background-color: ${({ theme }) => theme.colors["01Primary100"]};
        border-radius: 50%;
      }

      &--selected {
        background-color: ${({ theme }) => theme.colors.button};
        color: ${({ theme }) => theme.colors["01Primary0"]};

        &:hover {
          background-color: ${({ theme }) => theme.colors.button};
          border-radius: 50%;
        }
      }

      &-names {
        display: flex;
        padding: 0 5.5px;
        border-top: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
        border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
      }

      &-name {
        color: unset;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        &:nth-last-child(-n + 2) {
          color: ${({ theme }) => theme.colors.error};
        }
      }

      &--weekend {
        color: ${({ theme }) => theme.colors.error};
      }

      &--outside-month {
        opacity: 0.35;
      }
    }

    &-time__header {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: ${({ theme }) => theme.colors["01Primary700"]};
      padding: 12px 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
    }

    &__time {
      background: ${({ theme }) => theme.colors["01Primary0"]};
      &-container {
        width: 124px;
      }

      &-box {
        width: 124px !important;
      }

      &-list-item {
        height: unset !important;
        font-size: 16px;
        line-height: 24px;

        &:hover {
          background-color: ${({ theme }) => theme.colors["01Primary100"]} !important;
        }

        &--selected {
          background-color: ${({ theme }) => theme.colors.button} !important;

          &:hover {
            background-color: ${({ theme }) => theme.colors.button} !important;
          }
        }
      }
    }
  }
`;

const StyledInput = styled(Box)`
  cursor: pointer;
  width: ${({ timeInput }) => (timeInput ? "124px" : "283px")};
  height: 56px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
  display: flex;
  padding: 0 1rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  ${({ invalid }) => invalid && `border: 2px solid #f16d67;`}

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

const ValidationText = styled(Text)`
  color: #f16d67;
  text-align: left;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export default DateTimePicker;
